import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import HeroAbout from "../components/Sections/HeroAbout"
import SEO from "../components/SEO/index"
import StyledBackgroundAbout from "../components/BackgroundAbout"
import { Section } from "../components/CompoundComponents/Section"

import "./mystyles.scss"

const AboutPage = ({ data, intl, location }) => {
  return (
    <>
      <SEO title="Fanomed clinics" description="Fanomed clinics" />
      <StyledBackgroundAbout>
        <HeroAbout location={location} data="header.about.item-1" />
      </StyledBackgroundAbout>
      <Section white>
        <div className="content">
          <p>
            {intl.formatMessage({
              id: `about-page.desc-1`,
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: `about-page.desc-2`,
            })}
          </p>
        </div>
      </Section>
    </>
  )
}

export default injectIntl(AboutPage)
